import {
  LEAD_RESOURCE,
  CUSTOMER_RESOURCE,
  CONSULTANT_RESOURCE,
  CAMPAIGN,
  READ_ACTION,
  LEAD_CUSTOMER,
  CREATE_ACTION,
  APPOINTMENT,
  MANAGE_ACTION,
  OPPORTUNITY_MAP,
  TRANSFER_LEAD,
  PENDING_INTERVIEWS,
  TRANSFER_CUSTOMER
} from "@/constants/resources";

export default [
  // {
  //   header: "Gestão de clientes",
  //   resource: LEAD_RESOURCE,
  //   action: READ_ACTION,
  // },
  {
    title: "Gestão de clientes",
    icon: "none",
    children: [
      // 2nd level
      {
        title: "Leads clientes",
        route: "leads-customers-list",
        icon: "UserPlusIcon",
        resource: LEAD_CUSTOMER,
        action: READ_ACTION,
      },
      {
        title: "Transferir leads",
        route: "leads-transfer",
        icon: "RepeatIcon",
        resource: TRANSFER_LEAD,
        action: MANAGE_ACTION,
      },
      {
        title: "Clientes",
        route: "customers-list",
        icon: "UserIcon",
        resource: CUSTOMER_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Transferir clientes",
        route: "customers-transfer",
        icon: "RefreshCwIcon",
        resource: TRANSFER_CUSTOMER,
        action: MANAGE_ACTION,
      },
      {
        title: "Compromissos",
        route: "appointments",
        icon: "CalendarIcon",
        resource: APPOINTMENT,
        action: CREATE_ACTION,
      },
      {
        title: "Campanhas",
        route: "campaigns",
        icon: "ShoppingBagIcon",
        resource: CAMPAIGN,
        action: READ_ACTION,
      },
      {
        title: "Mapa de oportunidades",
        route: "opportunity-map-data-studio",
        icon: "MapIcon",
        resource: OPPORTUNITY_MAP,
        action: MANAGE_ACTION,
      },
      {
        title: "EFs sem EP",
        route: "pending-interviews-data-studio",
        icon: "InfoIcon",
        resource: PENDING_INTERVIEWS,
        action: READ_ACTION,
      },
    ],
  },
];
